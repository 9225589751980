import styled from 'styled-components';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  margin: ${({ theme }) => `${theme.spacing.xl} auto`};

  ${({ theme }) => theme.media.desktop} {
    padding: ${({ theme }) => `0 ${theme.spacing.xxl}`};
  }
`;
