import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import BrandImg from 'images/strike-brand.png';
import { TermsAccordion } from 'components/Pages/ReferAFriend';

const ReferAFriend = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Refer a friend"
          description="If you have an existing referral, you can find the T&C's below."
          canonical="/refer-a-friend"
          metaImage={BrandImg}
        />
        <Hero
          title="Sorry, our refer-a-friend programme is closed."
          subtitle="Don't worry, if you have an existing referral we will still honour it. Find our refer-a-friend terms and conditions below."
          backgroundPosition="center -50px"
        />
        <TermsAccordion />
      </Layout>
    </>
  );
};

export default ReferAFriend;
