import React from 'react';
import { Accordion, Text, Box } from '@strikelabs/luna';
import { identifier } from '@strikelabs/pax';
import { Element } from 'react-scroll';

import { Outer } from './style';

const TermsAccordion = () => (
  <Element
    name="raf-t&cs"
    {...identifier({ name: 'raf-t&cs', requireId: false })}
  >
    <Outer>
      <Box mb={[4, 4, 4, 5, 5]}>
        <Text as="h3" styleType="h2" ext>
          Terms &amp; Conditions
        </Text>
      </Box>
      <Accordion label="When do I get my reward?">
        Great question. Both you and your friend will get the reward when your
        friend&apos;s property listing goes live. We approve and send rewards
        once a month.
      </Accordion>
      <Accordion label="What if my friend doesn't list their property?">
        The reward is based off of listing a property with Strike, so your
        friend will have to complete the listing process for you both to
        qualify.
      </Accordion>
      <Accordion label="What if my friend takes their property off the market?">
        That&apos;s OK &mdash; sometimes people&apos;s circumstances change, and
        we understand that. As long as they&apos;ve gone through the listing
        process with Strike, you&apos;ll both get your rewards.
      </Accordion>
      <Accordion label="Are there any limitations on who I can refer?">
        Not really! You can&apos;t refer yourself and you can&apos;t refer
        anyone who owns the property with you, but other than that you can refer
        anyone &mdash; and as many people as you like. You&apos;ll only get one
        voucher per referral though, so even if you refer a couple or multiple
        person household, you&apos;ll receive &pound;50.
      </Accordion>
      <Accordion label="How do I get my Financial Services reward?">
        Easy &mdash; if the person referred buys their home with a mortgage or
        has a remortgage arranged by{' '}
        <a href="/services/mortgages/">Strike Financial Services</a>,
        you&apos;ll both get rewarded!
      </Accordion>
      <Accordion label="When do I get my Financial Services reward?">
        Great question. If you referred your friend to Strike Financial Services
        you&apos;ll both get your reward once the sale completes.
      </Accordion>
      <Accordion label="Can I refer the same friend to both the estate agency and the mortgage advice service?">
        No, you can&apos;t refer the same friend for different services, sorry
        about that. You can only get &pound;50 per friend &mdash; meaning you
        would get it when their house is listed OR when they use the mortgage
        service. For a chance to get more &pound;&pound;&pound; though, why not
        refer several friends? There&apos;s no limit to how many different
        friends you can send our way.
      </Accordion>
    </Outer>
  </Element>
);

export default TermsAccordion;
